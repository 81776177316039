angular.module("app").controller("EventsListPageController", [
    '$scope',
    'preloadedEvents',
    function ($scope, preloadedEvents) {
        $scope.events = angular.copy(preloadedEvents.posts);

        $scope.getTimeStamp = function (dateString) {
            return new Date(moment(dateString)._d).getTime();
        };
    }
]);