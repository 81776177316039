angular.module('jcs-autoValidate').run([
    'defaultErrorMessageResolver',
    function (defaultErrorMessageResolver)
    {
        defaultErrorMessageResolver.getErrorMessages().then(function (errorMessages)
        {
            errorMessages['titleRequired'] = 'validationErrorMessages.titleRequiredMsg';
            errorMessages['fieldRequired'] = 'validationErrorMessages.fieldRequired';
            errorMessages['minLengthError'] = 'validationErrorMessages.minLengthErrorMsg';
            errorMessages['onlyNumeric'] = 'validationErrorMessages.onlyNumeric';
            errorMessages['noMatch'] = 'validationErrorMessages.noMatch';
        });
    }
]);