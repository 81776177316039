angular.module("app").controller("MainPageController", [
    '$scope',
    function ($scope) {
    }
]);


angular.module("app").controller("HomePageController", [
    '$scope',
    'preloadedGalleries',
    '$rootScope',
    'galleryService',
    '$timeout',
    function ($scope, preloadedGalleries, $rootScope, galleryService, $timeout) {
        setGalleries(angular.copy(preloadedGalleries.menu));

        $rootScope.$on("language:changed", function () {
            resetData(function () {
                $timeout(function () {
                    SEMICOLON.setVariables();
                    SEMICOLON.documentOnLoad.init();
                    SEMICOLON.documentOnReady.init();
                });
            });
        });

        function resetData (cb) {
            galleryService.getList({json: 'get_page', slug: 'gallery', limit:4}).then(
                function (response) {
                    if (response.status == "ok") {
                        setGalleries(angular.copy(response.menu));
                        cb && cb();
                    }
                }
            );
        }

        function setGalleries (galleries) {
            $scope.items = [];
            for (var i = 0, length = galleries.length; i < length; i++) {
                addToItems(galleries[i].children, galleries[i].name)
            }
        }

        function addToItems (arr, asFilter) {
            for (var i = 0, length = arr.length; i < length; i++) {
                arr[i].filterField = asFilter;
                $scope.items.push(arr[i])
            }
        }


    }
]);