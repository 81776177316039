angular.module("app").factory("httpHandler", [
    'settingsService',
    function (settingsService) {
        return {
            request: function (request) {
                return request
            },
            response : function (response) {
                return response
            }
        };
    }
]).config(['$httpProvider', function($httpProvider) {
    $httpProvider.interceptors.push('httpHandler');
}]);