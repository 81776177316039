angular.module("app").config(function ($stateProvider, $urlRouterProvider) {
    var defaultRoute = "main.home";


    $urlRouterProvider.otherwise(function ($injector) {
        $injector.invoke(['$state', function ($state) {
            $state.go(defaultRoute);
        }]);
    });

    $stateProvider.state("main", {
        abstract: true,
        url: "/",
        templateUrl: "views/main.html",
        controller: "MainPageController",
        ncyBreadcrumb: {
            skip: true
        }
    }).state("main.home", {
        url: "home",
        templateUrl: "views/home.html",
        controller: "HomePageController",
        ncyBreadcrumb: {
            label: 'breadcrumb.pages.home'
        },
        resolve: {
            preloadedGalleries: function (galleryService) {
                return galleryService.getList({json: 'get_page', slug: 'gallery', limit:4}).then(
                    function (response) {
                        if (response.status == "ok") {
                            return response
                        }
                        return []
                    },
                    function (response) {
                        return []
                    }
                )
            }
        }
    }).state("main.aboutUs", {
        abstract: true,
        url: "about-us",
        templateUrl: "views/aboutUs/aboutUs.html",
        ncyBreadcrumb: {
            label: 'breadcrumb.pages.aboutUs'
        }
    }).state("main.aboutUs.view", {
        url: "/:aboutMenuId",
        templateUrl: "views/aboutUs/view.html",
        controller: "ReadGenericDataController",
        ncyBreadcrumb: {
            parent: 'main.home',
            label: '{{getPageLabel({aboutUs: true})}}'
        },
        resolve: {
            preloadedData: function (pagesService, $stateParams) {
                return pagesService.getPageById({json: 'get_page', id: $stateParams.aboutMenuId}).then(
                    function (response) {
                        return response.page
                    },
                    function (response) {
                        return {}
                    }
                )
            }
        }
    }).state("main.education", {
        abstract: true,
        url: "education",
        templateUrl: "views/education/education.html",
        ncyBreadcrumb: {
            label: 'breadcrumb.pages.education'
        }
    }).state("main.education.view", {
        url: "/:educationMenuId",
        templateUrl: "views/aboutUs/view.html",
        controller: "ReadGenericDataController",
        ncyBreadcrumb: {
            parent: 'main.home',
            label: '{{getPageLabel({aboutUs: false})}}'
        },
        resolve: {
            preloadedData: function (pagesService, $stateParams) {
                return pagesService.getPageById({json: 'get_page', id: $stateParams.educationMenuId}).then(
                    function (response) {
                        return response.page
                    },
                    function (response) {
                        return {}
                    }
                )
            }
        }
    }).state("main.events", {
        url: "events",
        templateUrl: "views/events/events-list.html",
        controller: "EventsListPageController",
        resolve: {
            preloadedEvents: function (eventsService) {
                return eventsService.getList({json: 'get_page', slug: 'event'}).then(
                    function (response) {
                        if (response.status == "ok") {
                            return response
                        }
                        return []
                    },
                    function (response) {
                        return []
                    }
                )
            }
        },
        ncyBreadcrumb: {
            parent: 'main.home',
            label: 'breadcrumb.pages.events'
        }
    }).state("main.viewEvent", {
        url: "events/:eventId",
        templateUrl: "views/events/event-single.html",
        controller: "ViewEventPageController",
        resolve: {
            preloadedEventData: function ($stateParams, eventsService) {

                return eventsService.getById({json: 'get_post', slug: 'event', id: $stateParams.eventId}).then(
                    function (response) {
                        if (response.status == "ok") {
                            return response
                        }
                        return []
                    },
                    function (response) {
                        return []
                    }
                )
            }
        },
        ncyBreadcrumb: {
            parent: "main.events",
            label: 'breadcrumb.pages.event'
        }
    }).state("main.gallery", {
        url: "gallery",
        templateUrl: "views/gallery/gallery.html",
        controller: "GalleryListPageController",
        resolve: {
            preloadedGalleries: function (galleryService) {
                return galleryService.getList({json: 'get_page', slug: 'gallery'}).then(
                    function (response) {
                        if (response.status == "ok") {
                            return response
                        }
                        return []
                    },
                    function (response) {
                        return []
                    }
                )
            }
        },
        ncyBreadcrumb: {
            parent: 'main.home',
            label: 'breadcrumb.pages.gallery'
        }
    }).state("main.viewGallery", {
        url: "gallery/:galleryId",
        templateUrl: "views/gallery/viewGallery.html",
        controller: "ViewGalleryPageController",
        resolve: {
            preloadedEvents: function (galleryService, $stateParams) {
                return galleryService.getById({json: 'get_page', slug: 'gallery', id: $stateParams.galleryId}).then(
                    function (response) {
                        if (response.status == "ok") {
                            return response
                        }
                        return {}
                    },
                    function () {
                        return {}
                    }
                )
            }
        },
        ncyBreadcrumb: {
            parent: 'main.gallery',
            label: 'breadcrumb.pages.gallery'
        }
    }).state("main.blogs", {
        url: "blog",
        templateUrl: "views/blogs/blogs.html",
        controller: "BlogsListPageController",
        resolve: {
            preloadedBlogs: function (blogsService) {
                return blogsService.getList({json: 'get_page', slug: 'blog'}).then(
                    function (response) {
                        if (response.status == "ok") {
                            return response
                        }
                        return []
                    },
                    function (response) {
                        return []
                    }
                )
            }
        },
        ncyBreadcrumb: {
            parent: 'main.home',
            label: 'breadcrumb.pages.blogs'
        }
    }).state("main.viewBlog", {
        url: "blog/:blogId",
        templateUrl: "views/blogs/viewBlog.html",
        params: {anchor: false},
        controller: "ViewBlogPageController",
        resolve: {
            preloadedBlogData: function (blogsService, $stateParams) {
                return blogsService.getById({json: 'get_post', slug: 'blog', id: $stateParams.blogId}).then(
                    function (response) {
                        if (response.status == "ok") {
                            return response
                        }
                        return []
                    },
                    function (response) {
                        return []
                    }
                )
            }
        },
        ncyBreadcrumb: {
            parent: 'main.blogs',
            label: 'breadcrumb.pages.blog'
        }
    }).state("main.contactUs", {
        url: "contact-us",
        templateUrl: "views/contactUs/contactUs.html",
        ncyBreadcrumb: {
            parent: 'main.home',
            label: 'breadcrumb.pages.contactUs'
        }
    })

}).config(function ($breadcrumbProvider) {
    $breadcrumbProvider.setOptions({
        templateUrl: "views/breadcrumb/breadcrumbTemplate.html"
    });
});