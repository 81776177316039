angular.module("app").factory("settingsService", [
    'preLoadedData',
    function (preLoadedData) {
        var info = {};

        if (!!preLoadedData.data && preLoadedData.data.status == "ok") {
            setSettings(preLoadedData.data);
        }

        function  setSettings (settings) {
            var allSettings = angular.copy(settings);
            info.settings = angular.copy(allSettings);
        }

        function getSettings () {
            return info.settings;
        }

        return {
            setSettings: setSettings,
            getSettings: getSettings
        }
    }
]);