angular.module('app').factory('validationElementModifier', [
    "$timeout",
    function ($timeout)
    {
        var makeValid = function (el)
        {
            var scope = el.scope();
            if (scope.tooltip && scope.tooltip.hasOwnProperty(el.attr('name')))
            {
                scope.tooltip[el.attr('name')] = "";
            }
        };

        var makeInvalid = function (el, errorMsg)
        {
            var scope = el.scope();
            if (scope.tooltip && scope.tooltip.hasOwnProperty(el.attr('name')))
            {
                scope.tooltip[el.attr('name')] = errorMsg;

                $timeout(function ()
                {
                    el.triggerHandler('mouseenter');
                }, 0);
            }
        };

        var makeDefault = function (el)
        {
            var scope = el.scope();
            if (scope.tooltip && scope.tooltip.hasOwnProperty(el.attr('name')))
            {
                scope.tooltip[el.attr('name')] = "";
            }
        };

        return {
            makeValid: makeValid,
            makeInvalid: makeInvalid,
            makeDefault: makeDefault,
            key: 'validationModifierKey'
        };
    }
])