angular.module("app", [
    'restangular',
    'ui.router',
    'ui.bootstrap',
    'ncy-angular-breadcrumb',
    'ngCookies',
    'pascalprecht.translate',
    'ngAnimate',
    'jcs-autoValidate',
    'projectFilters'

    //custom modules
]).run([
    '$rootScope',
    '$state',
    'validator',
    'validationElementModifier',
    '$timeout',
    '$anchorScroll',
    function ($rootScope, $state,  validator, validationElementModifier, $timeout, $anchorScroll) {

        $anchorScroll.yOffset = 70;
        validator.registerDomModifier(validationElementModifier.key, validationElementModifier);
        validator.setDefaultElementModifier(validationElementModifier.key);

        $rootScope.$on('$stateChangeStart', function () {
            $("#wrapper").removeClass("fadeIn").addClass("fadeOut");
            $(".customSpinner").show();
        });

        $rootScope.$on('$stateChangeSuccess', function () {
            $("#wrapper").removeClass("fadeOut").addClass("fadeIn");
            $(".customSpinner").hide();

            $timeout(function () {
                SEMICOLON.setVariables();
                SEMICOLON.documentOnLoad.init();
                SEMICOLON.documentOnReady.init();
                $("#wrapper").removeClass("fadeOut").addClass("fadeIn");
                $(".customSpinner").hide();
            }, 500);
        });

    }
]).config(function($sceProvider) {
    // Completely disable SCE.  For demonstration purposes only!
    // Do not use in new projects.
    $sceProvider.enabled(false);
});



