angular.module("app").directive("pageContent", [
    '$compile',
    '$timeout',
    function ($compile, $timeout) {
        return {
            restrict: "E",
            link: function ($scope, element) {
                var template = $scope.pageData.content;
                template = angular.element(template);
                $(element).replaceWith(template);
                $compile(template)($scope);
            }
        }
    }
]);