angular.module("app").controller("HeaderController", [
    '$scope',
    '$state',
    'settingsService',
    '$rootScope',
    'pagesService',
    function ($scope, $state, settingsService, $rootScope, pagesService) {
        $scope.$state = $state;
        $scope.menu = settingsService.getSettings().menu;

        $rootScope.$on("language:changed", function () {
            resetData();
        });

        function resetData () {
            pagesService.getGeneralMenu().then(
                function (response) {
                    settingsService.getSettings().menu = angular.copy(response.menu);
                    $scope.menu = settingsService.getSettings().menu;
                }
            )

        }

    }
]);