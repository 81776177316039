(function () {
    var cookies;

    function readCookie(name, c, C, i) {
        if (cookies) {
            return cookies[name];
        }

        c = document.cookie.split('; ');
        cookies = {};

        for (i = c.length - 1; i >= 0; i--) {
            C = c[i].split('=');
            cookies[C[0]] = C[1];
        }

        return cookies[name];
    }

    window.readCookie = readCookie; // or expose it however you want
})();

setPristineObjects();

function preLoadNecessaryData() {
    return $.ajax({
        url: "https://backoffice.cherry.school/json",
        method: "GET",
        dateType: "JSON",
        data: {lang: window.readCookie("language") || 'ru', slug: 'general-menu', json: 'get_page'}
    })
}

function wire(data) {
    angular.module("app").value("preLoadedData", {data: data});
    document.getUserData = function () {
        return angular.copy(data.userInfo);
    };
    runModule()
}

function setPristineObjects() {
    angular.module("app").value("preLoadedData", {data: null});
}


function forceWire() {

    angular.module("app").value("preLoadedData", {data: getMock()});
    //angular.bootstrap($(document.body), ["app"]);
    runModule()
}

function runModule() {
    angular.element(document.body).ready(function () {
        angular.bootstrap($(document.body), ["app"]);
    });
}

//app wire point
preLoadNecessaryData().success(wire).error(forceWire);


(function ($) {

    $.fn.extend({

        smartpreload: function (options) {

            var settings = $.extend({

                images: null,

                oneachimageload: null,

                onloadall: null

            }, options);

            return this.each(function () {

                var loadcounter = 0;

                for (var i = 0; i < settings.images.length; i++) {

                    var img = $('<img/>').addClass('preloading').css('display', 'none').attr('src', settings.images[i]).load(
                        function () {

                            loadcounter++;

                            if (settings.oneachimageload != null) settings.oneachimageload($(this).attr('src'));

                            if (loadcounter == settings.images.length) {

                                if (settings.onloadall != null) settings.onloadall();

                            }

                        }
                    ).error(function () {
                            loadcounter++;
                            if (settings.oneachimageload != null) settings.oneachimageload($(this).attr('src'));

                            if (loadcounter == settings.images.length) {

                                if (settings.onloadall != null) settings.onloadall();

                            }

                        });

                }

            });

        }

    });

})(jQuery);


function getMock() {
    return {
        languages: [],
        lang: "ru",
        status: "ok"
    }
}


/*
 $(document).smartpreload({
 images: [
 'images/super_nova.jpg',
 'images/pacific-ocean-from-space.jpg',
 'images/dark_sun_dawn.jpg',
 'images/abstract_lake.jpg',
 'images/abstract_brushes.jpg',
 'images/earth.jpg',
 'images/moon-arc.jpg',
 'images/moon-day.jpg',
 'images/saguaroMoon_seip.jpg',
 'images/solar-system.jpg',
 'images/space.jpg',
 'images/tricompSW.jpg'
 ],
 oneachimageload: function () {
 },
 onloadall: function () {
 preloadedImages = true;
 runModule();
 }

 });*/