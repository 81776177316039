function param (a, traditional) {
    var add, buildParams, item, prefix, s, i, len;
    if (traditional == null) {
        traditional = false;
    }
    prefix = void 0;
    s = [];
    add = function(key, value) {
        value = (angular.isFunction(value) ? value() : (value == null ? "" : value));
        return s[s.length] = encodeURIComponent(key) + "=" + encodeURIComponent(value);
    };
    buildParams = function(prefix, obj, traditional) {
        var i, name, v, results, results1;
        name = void 0;
        if (angular.isArray(obj)) {
            _results = [];
            for (i in obj) {
                v = obj[i];
                if (traditional || /\[\]$/.test(prefix)) {
                    _results.push(add(prefix, v));
                } else {
                    _results.push(buildParams(prefix + "[" + (typeof v === "object" ? i : "") + "]", v, traditional, add));
                }
            }
            return _results;
        } else if (!traditional && angular.isObject(obj)) {
            _results1 = [];
            for (name in obj) {
                _results1.push(buildParams(prefix + "[" + name + "]", obj[name], traditional, add));
            }
            return _results1;
        } else {
            return add(prefix, obj);
        }
    };
    if (angular.isArray(a)) {
        for (i = 0, len = a.length; i < len; _i++) {
            item = a[_i];
            add(item.name, item.value);
        }
    } else {
        for (prefix in a) {
            buildParams(prefix, a[prefix], traditional);
        }
    }
    return "?" + s.join("&").replace(/%20/g, "+");
}