angular.module("app").controller("GalleryListPageController", [
    '$scope',
    'preloadedGalleries',
    'galleryService',
    '$state',
    function ($scope, preloadedGalleries, galleryService, $state) {
        $scope.galleries = angular.copy(preloadedGalleries.menu);


        $scope.items = [];


        for (var i = 0, length = $scope.galleries.length; i < length; i++) {
            addToItems($scope.galleries[i].children, $scope.galleries[i].name)
        }

        function addToItems (arr, asFilter) {
            for (var i = 0, length = arr.length; i < length; i++) {
                arr[i].filterField = asFilter;
                $scope.items.push(arr[i])
            }
        }

        $scope.viewGallery = function (id) {
            $state.go('main.viewGallery', {galleryId: id})
        }

    }
]);