angular.module("app").controller("ViewEventPageController", [
    '$scope',
    'preloadedEventData',
    '$anchorScroll',
    '$location',
    '$stateParams',
    '$timeout',
    function ($scope, preloadedEventData, $anchorScroll, $location, $stateParams, $timeout) {
        $scope.pageData = angular.copy(preloadedEventData.item);

        $scope.getTimeStamp = function (dateString) {
            return new Date(moment(dateString)._d).getTime();
        };

        $scope.gotoAnchor = function(x) {
            $location.hash(x);
            $anchorScroll();
        };

        $timeout(function () {
            $scope.gotoAnchor("page-title")
        }, 300)
    }
]);