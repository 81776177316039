angular.module("app").controller("ReadGenericDataController", [
    '$scope',
    'preloadedData',
    'settingsService',
    '$stateParams',
    function ($scope, preloadedData, settingsService, $stateParams) {
        var id = $stateParams.aboutMenuId || $stateParams.educationMenuId,
            settings = settingsService.getSettings(),
            labelCash = '';

        $scope.pageData = angular.copy(preloadedData);
        $scope.pageOption = {
            label: 'breadcrumb.pages.viewAboutUs'
        };
        $scope.getPageLabel = function (pageOption) {
            if (pageOption.aboutUs) {
                return findLabalById(true, id)
            }
            return findLabalById(false, id)
        };


        function findLabalById (place, id) {
            if (labelCash) {
                return labelCash
            }
            if (place) {
                // about-us
                for (var i = 0, length = settings.menu.about_us.children.length, menu = angular.copy(settings.menu.about_us.children); i < length; i++) {
                    for (var j = 0, sLength = menu[i].children.length; j < sLength; j++) {
                        if (id == menu[i].children[j].id) {
                            labelCash = menu[i].title.trim() + '\xa0\xa0\xa0/\xa0\xa0\xa0' + menu[i].children[j].title;
                            return labelCash
                        }
                    }
                }
            } else {
                // education
                for (var i = 0, length = settings.menu.education.children.length, menu = angular.copy(settings.menu.education.children); i < length; i++) {
                    for (var j = 0, sLength = menu[i].children.length; j < sLength; j++) {
                        if (id == menu[i].children[j].id) {
                            labelCash = menu[i].title.trim() + '\xa0\xa0\xa0/\xa0\xa0\xa0'  + menu[i].children[j].title;
                            return labelCash
                        }
                    }
                }
            }

        }
    }
]);