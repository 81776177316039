angular.module('app').factory('eventsService', [
    'Restangular',
    'settings',
    '$cookies',
    function (Restangular, settings, $cookies) {
        var restAngular = Restangular.withConfig(function (Configurer) {
            Configurer.setBaseUrl(settings.apiURL);
        });

        restAngular = restAngular.all("/json/");

        return {
            getList: function (requestData) {
                requestData.lang = requestData.lang || $cookies.get("language") || 'ru';
                return restAngular.
                    customGET(param(requestData))
            },
            getById: function (requestData) {
                requestData.lang = requestData.lang || $cookies.get("language") || 'ru';
                return restAngular.
                    customGET(param(requestData))
            }
        }
    }
]);


angular.module('app').factory('pagesService', [
    'Restangular',
    'settings',
    '$cookies',
    function (Restangular, settings, $cookies) {
        var restAngular = Restangular.withConfig(function (Configurer) {
            Configurer.setBaseUrl(settings.apiURL);
        });

        restAngular = restAngular.all("/json/");

        return {
            getPageById: function (requestData) {
                requestData.lang = requestData.lang || $cookies.get("language") || 'ru';
                return restAngular.
                    customGET(param(requestData))
            },
            getGeneralMenu: function () {
                var requestData = {
                    lang: $cookies.get("language") || 'ru',
                    slug: 'general-menu',
                    json: 'get_page'
                };

                return restAngular.
                    customGET(param(requestData))

            }
        }
    }
]);


angular.module('app').factory('galleryService', [
    'Restangular',
    'settings',
    '$cookies',
    function (Restangular, settings, $cookies) {
        var restAngular = Restangular.withConfig(function (Configurer) {
            Configurer.setBaseUrl(settings.apiURL);
        });

        restAngular = restAngular.all("/json/");

        return {
            getList: function (requestData) {
                requestData.lang = requestData.lang || $cookies.get("language") || 'ru';
                return restAngular.
                    customGET(param(requestData))
            },
            getById: function (requestData) {
                requestData.lang = requestData.lang || $cookies.get("language") || 'ru';
                return restAngular.
                    customGET(param(requestData))
            }
        }
    }
]);

angular.module('app').factory('blogsService', [
    'Restangular',
    'settings',
    '$cookies',
    function (Restangular, settings, $cookies) {
        var restAngular = Restangular.withConfig(function (Configurer) {
            Configurer.setBaseUrl(settings.apiURL);
        });

        restAngular = restAngular.all("/json/");

        return {
            getList: function (requestData) {
                requestData.lang = requestData.lang || $cookies.get("language") || 'ru';
                return restAngular.
                    customGET(param(requestData))
            },
            getById: function (requestData) {
                requestData.lang = requestData.lang || $cookies.get("language") || 'ru';
                return restAngular.
                    customGET(param(requestData))
            }
        }
    }
]);