angular.module("app").controller("ViewBlogPageController", [
    '$scope',
    'preloadedBlogData',
    '$anchorScroll',
    '$location',
    '$stateParams',
    '$timeout',
    function ($scope, preloadedBlogData, $anchorScroll, $location, $stateParams, $timeout) {
        $scope.pageData = preloadedBlogData.item;
        var anchor = $stateParams.anchor;


        $scope.getTimeStamp = function (dateString) {
            return new Date(dateString).getTime();
        };

        $scope.gotoAnchor = function(x) {
            $location.hash(x);
            $anchorScroll();
        };

        $timeout(function () {
            $scope.gotoAnchor("page-title")
        }, 300)
    }
]);