angular.module("app").controller("LanguageController", [
    '$scope',
    'settingsService',
    '$translate',
    '$cookies',
    '$filter',
    '$state',
    '$rootScope',
    function ($scope, settingsService, $translate, $cookies, $filter, $state, $rootScope) {
        var translate = $filter("translate");
        var settings = settingsService.getSettings();
        $scope.currentLanguageName = $cookies.get("language") || settings.lang;
        $translate.use($scope.currentLanguageName || 'ru');
        $scope.changeLanguage = function (langCode) {
            $cookies.put('language', langCode);
            $scope.currentLanguageName = langCode;
            $translate.use(langCode);

            $rootScope.$broadcast("language:changed");
            if ($state.$current.name != 'main.home') {
                switch ($state.$current.name) {
                    case 'main.viewBlog':
                        return $state.go("main.blogs");
                    case 'main.viewEvent':
                        return $state.go("main.events");
                    default :
                        return $state.reload()
                }
            }
        }
    }
]);