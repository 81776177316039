angular.module("app").controller("FooterController", [
    '$scope',
    'eventsService',
    'blogsService',
    '$timeout',
    '$rootScope',
    '$q',
    function ($scope, eventsService, blogsService, $timeout, $rootScope, $q) {
        $scope.events = [];
        $scope.blogs = [];
        var pendings = [],
            deferer = [];


        $scope.getTimeStamp = function (dateString) {
            return new Date(moment(dateString)._d).getTime();
        };

        function updateBlogsList () {
            deferer[0] = $q.defer();
            deferer[0].promise.then(
                function (response) {
                    if (response.status == "ok") {
                        $scope.blogs = angular.copy(response.posts);
                    }
                    pendings[0] = $timeout(function () {
                        updateBlogsList();
                    }, 60000)
                },
                function () {}
            );

            blogsService.getList({json: 'get_page', slug: 'blog'}).then(
                deferer[0].resolve,
                deferer[0].reject
            )
        }


        function updateEventsList () {

            deferer[1] = $q.defer();
            deferer[1].promise.then(
                function (response) {
                    if (response.status == "ok") {
                        $scope.events = angular.copy(response.posts);
                    }
                    pendings[1] = $timeout(function () {
                        updateEventsList();
                    }, 60000)
                },
                function () {}
            );
            eventsService.getList({json: 'get_page', slug: 'event'}).then(
                deferer[1].resolve,
                deferer[1].reject
            )
        }

        $rootScope.$on("language:changed", function () {
            resetData(cancelPendings());
        });


        function resetData () {
            updateBlogsList();
            updateEventsList();
        }

        function cancelPendings () {
            deferer.forEach(function (val, index) {
                val.reject();
                $timeout.cancel(pendings[index]);
            })
        }

        resetData();
    }
]);