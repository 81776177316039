angular.module("app").controller("BlogsListPageController", [
    '$scope',
    'preloadedBlogs',
    '$filter',
    function ($scope, preloadedBlogs, $filter) {

        $scope.getTimeStamp = function (dateString) {
            return new Date(moment(dateString)._d).getTime();
        };

        $scope.blogs = angular.copy(preloadedBlogs.posts);

        $scope.blogs = $filter("orderBy")($scope.blogs, function (p) {return moment(p.created_at);}, true);

        $scope.blogs = $filter("groupingFilter")(
            $scope.blogs,

            function (p1, p2) {
                return ((moment(p1.created_at).month() == moment(p2.created_at).month()) && (moment(p1.created_at).year() == moment(p2.created_at).year()));
            },

            function (p) {
                return moment(p.created_at).startOf("month");
            }
        );
    }
]);