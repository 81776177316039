angular.module("app").directive("galleryHelper", [
    '$timeout',
    function ($timeout) {
        return {
            restrict: "A",
            link: function () {

                $timeout(function () {
                    SEMICOLON.setVariables();
                    SEMICOLON.initialize.lightbox();
                    SEMICOLON.portfolio.gridInit($('.grid-container'));
                    SEMICOLON.portfolio.filterInit();
                }, 1000)
            }
        }
    }
]);

angular.module("app").directive("blogHelper", [
    '$timeout',
    function ($timeout) {
        return {
            restrict: "A",
            link: function ($scope) {

                $timeout(function () {
                    var $container = $('#posts');

                    $container.isotope({
                        itemSelector: '.entry',
                        masonry: {
                            columnWidth: '.entry:not(.entry-date-section)'
                        }
                    });

                    $container.infinitescroll({
                            loading: {
                                finishedMsg: '<i class="icon-line-check"></i>',
                                msgText: '<i class="icon-line-loader icon-spin"></i>',
                                img: "images/preloader-dark.gif",
                                speed: 'normal'
                            },
                            state: {
                                isDone: false
                            },
                            nextSelector: "#load-next-posts a",
                            navSelector: "#load-next-posts",
                            itemSelector: "div.entry",
                            behavior: 'portfolioinfiniteitemsloader'
                        },
                        function( newElements ) {
                            $container.isotope( 'appended', $( newElements ) );
                            $container.isotope('layout');
                            SEMICOLON.initialize.resizeVideos();
                            SEMICOLON.widget.loadFlexSlider();
                            SEMICOLON.widget.masonryThumbs();
                            SEMICOLON.initialize.blogTimelineEntries();
                        });

                    SEMICOLON.initialize.blogTimelineEntries();

                    $(window).on('resize', resizeHandler);

                    function resizeHandler () {
                        $container.isotope('layout');
                        var t = setTimeout( function(){
                            SEMICOLON.initialize.blogTimelineEntries();
                        }, 2500 );
                    }

                    $scope.$on("$destroy", function () {
                        $(window).off('resize', resizeHandler)
                    })
                });




            }
        }
    }
]);


angular.module("app").directive("eventHelper", [
    '$timeout',
    function ($timeout) {
        return {
            restrict: "A",
            link: function ($scope) {
                var countDownElement;
                $timeout(function () {
                    var eventStartDate = new Date(moment($scope.pageData.start_date)._d);
                    countDownElement = $('#event-countdown').countdown({until: eventStartDate});
                });

                jQuery('#event-location').gMap({
                    address: $scope.pageData.link,
                    maptype: 'ROADMAP',
                    zoom: 15,
                    markers: [
                        {
                            address: $scope.pageData.link
                        }
                    ],
                    doubleclickzoom: false,
                    controls: {
                        panControl: true,
                        zoomControl: true,
                        mapTypeControl: true,
                        scaleControl: false,
                        streetViewControl: false,
                        overviewMapControl: false
                    }
                });

                $scope.$on("$destroy", function () {
                    countDownElement.countdown('destroy')
                })
            }
        }
    }
]);