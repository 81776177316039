angular.module("app").directive("homePage", [
    '$timeout',
    function ($timeout) {
        return {
            restrict: "A",
            link: function () {
                $('#google-map5').gMap({
                    address: '90 Oxford Gardens, London, W10 5UW, United Kingdom',
                    maptype: 'ROADMAP',
                    zoom: 12,
                    markers: [
                        {
                            address: "90 Oxford Gardens, London, W10 5UW, United Kingdom",
                            html: "NW10 school description goes here"
                        },
                        {
                            address: "80 Cambridge Gardens, London, W10 6HS, United Kingdom",
                            html: "W10 school description goes here"
                        },
                        {
                            address: "22 Vicars Road, London, NW5 4NL, United Kingdom",
                            html: "NW5 school description goes here"
                        },
                        {
                            address: "London, SW6 6HS, United Kingdom",
                            html: "SW6 6HS school description goes here"
                        },
                        {
                            address: "137 St Marks Road, London, W10 6BZ, United Kingdom",
                            html: "W10 school description goes here"
                        }
                    ],
                    doubleclickzoom: false,
                    controls: {
                        panControl: true,
                        zoomControl: true,
                        mapTypeControl: false,
                        scaleControl: false,
                        streetViewControl: false,
                        overviewMapControl: false
                    }
                });
            }
        }
    }
]);